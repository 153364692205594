'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { Loader } from '@sidetalk/react';

type LogoutTimeClientProps = {
    removeCookie: () => Promise<void>;
};

export function LogoutTimeClient({ removeCookie }: LogoutTimeClientProps) {
    const router = useRouter();

    useEffect(() => {
        const onRemoveCookie = async () => {
            await removeCookie();

            localStorage.setItem('logout', String(Date.now()));

            router.replace('/auth/login');
        };

        onRemoveCookie();
    }, [router, removeCookie]);

    return <Loader.Byside />;
}
